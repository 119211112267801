.loginPage {
  min-height: calc(100vh - 60px);
  background-color: #f0f0f0;
}

.loginPageContainer {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 1rem;
}

@media (min-width: 768px) {
  .loginPageContainer {
    gap: 40px;
    /* padding: 100px 1.5rem; */
  }
}

.backLoginButton {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  font-weight: 600;
}

.loginContainer {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 28px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: var(--white);
  border-radius: 5px;
  text-align: center;
}

@media (min-width: 600px) {
  .loginContainer {
    padding: 40px;
  }
}

.loginContainer img {
  width: 63px;
}

.loginContainer .descriptive{
  font-style: italic;
  font-size: 12px;
  line-height: 18px;
}

.loginContainer .info {
  max-width: 350px;
  font-size: 25px;
  line-height: 28px;
}

.formLogin {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.formLogin__inputs {
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.formLogin__inputs div {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 5px;
}

.formLogin__inputs div .input-container {
  position: relative;
  font-size: 16px;
}

.input-container label {
  font-weight: 600;
}

.input-container input {
  width: 100%;
  padding-left: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 40px;
  font-size: 16px;
}

.input-container svg {
  position: absolute;
  top: 42px;
  left: 5px;
  color: #888;
}

.btnLogin {
  background-color: var(--green-aquinas);
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
}
