.newsCarousel {
  background-color: var(--white);
  /* height: 460px; */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  z-index: 2;
  width: 100%;
  padding: 15px;
  margin: 50px auto;
  border-radius: 5px;
  max-width: 410px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.newsCarouselImageContainer {
  height: 220px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.newsCarouselImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
}

.newsCarouselInformation {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.newsCarouselInformationDate {
  display: flex;
  gap: 10px;
  text-align: start;
  align-items: center;
  justify-content: start;
  height: 28px;
}

.newsCarouselInformationDate p:first-child {
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #f87052;
  height: 100%;
  width: 40px;
  font-size: 13px;
  font-weight: 600;
}
.newsCarouselInformationDate p:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--light-grey);
  color: #212529;
  height: 100%;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
}

.newsCarouselTitle {
  font-size: 20px;
  font-weight: 600;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.newsCarouselText {
  font-size: 15px;
  font-weight: 400;
  text-align: start;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.newsCarouselButton {
  text-align: start;
  color: var(--green-aquinas);
  font-size: 14px;
  font-weight: 400;
  text-decoration-line: underline;
}

/* ESTILO DE DOTS CARRUSEL */

.newsHome .alice-carousel__dots {
  margin: 0;
}

.newsHome .alice-carousel__dots-item {
  background-color: rgba(255, 255, 255, 0.26);
}

.newsHome .alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: var(--green-aquinas);
}

.carouselNewsInfoPage .alice-carousel__prev-btn {
  position: absolute;
  left: -25px;
  top: 50%;
  background-color: var(--white);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  border: 0.3px solid rgba(27, 40, 49, 0.2);
  color: var(--blue-aquinas);
}
.carouselNewsInfoPage .alice-carousel__prev-btn p:hover {
  color: black;
}
.carouselNewsInfoPage .alice-carousel__next-btn {
  right: -10px;
  position: absolute;
  top: 50%;
  background-color: var(--white);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  border: 0.3px solid rgba(27, 40, 49, 0.2);
  color: var(--blue-aquinas);
  color: red
}
.carouselNewsInfoPage .alice-carousel__next-btn p:hover {
  color: black;
}

.carouselNewsInfoPage .alice-carousel__stage-item {
  text-align: center;
}
