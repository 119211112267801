.searchBar {
  height: 100px;
  width: calc(100% - 80px);
  margin-left: 80px;
  border-bottom: 1px solid rgba(27, 40, 49, 0.23);
  position: fixed;
  top: 0;
  background-color: var(--white);
  z-index: 59;
}

@media (min-width: 1024px) {
  .searchBar {
    margin-left: 0;
    padding-left: 80px;
    width: 100%;
    padding-left: 300px;
  }
}

.searchBarContainer {
  max-width: 1600px;
  padding: 0 1.5rem 0 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: end;
  margin: 0 auto;
  gap: 10px;
}
