.ia {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.iaContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.iaHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
}

.iaHeaderOptions {
  display: flex;
  gap: 10px;
}

.iaHeaderOptions svg{
  font-size: 22px;
}

.iaMain {
  height: 100%;
  height: calc(100vh - 100px);
  max-width: 1200px;
  margin: 0 auto;
}

.iaMain .info {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

@media (min-width: 768px) {
  .iaMain .info {
    gap: 100px;
  }
}

.infoResponseContainer {
  border-radius: 0px 10px 10px 10px;
  border: 0.3px solid rgba(27, 40, 49, 0.234);
  background-color: rgba(27, 40, 49, 0.02);
  padding: 10px;
}

@media (min-width: 768px) {
  .infoResponseContainer {
    padding: 25px;
  }
}

.infoResponse {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.infoResponse p {
  font-size: 14px;
}

@media (min-width: 768px) {
  .infoResponse p {
    font-size: 15px;
  }
}
.infoResponse summary {
  font-size: 15px;
}

@media (min-width: 768px) {
  .infoResponse summary {
    font-size: 16px;
  }
}
.infoResponse__info {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 400;
}

.infoResponse__info div {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoResponse__answer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.answerContainer {
  text-align: justify;
  margin-left: 10px;
}

.infoResponse_Related {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.containerPrompt {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.promtFetched {
  color: var(--green-aquinas);
  font-weight: 400;
}

.prompt {
  user-select: none;
  display: flex;
  justify-content: end;
}

.prompt p {
  font-size: 15px;
  color: var(--green-aquinas);
  padding: 10px 15px;
  background: linear-gradient(
      0deg,
      rgba(0, 188, 146, 0.15) 0%,
      rgba(0, 188, 146, 0.15) 100%
    ),
    var(--White, #fff);
  border-radius: 10px 0px 10px 10px;
}

.searchBarIAContainer {
  position: absolute;
  bottom: 0;
  width: calc(100% - 48px);
  max-width: 1200px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  left: 50%;
  transform: translateX(-50%);
}
.searchBarIAContainer p {
  font-size: 12px;
  text-align: center;
  margin-bottom: 10px;
}

@media (min-width: 500px) {
  .searchBarIAContainer p {
    margin-bottom: 20px;
  }
}

.auxContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (min-width: 768px) {
  .auxContainer {
    margin-left: 10px;
  }
}

.auxQuestion {
  background-color: #fff;
  padding: 8px 15px;
  border-radius: 8px;
  display: inline-block;
  transition: 0.3s;
  border: 0.3px solid rgba(27, 40, 49, 0.09);
}

.auxQuestion:hover {
  background-color: rgba(0, 188, 146, 0.28);
}

.auxAnswer {
  margin-top: 10px;
  margin-left: 10px;
}

.relatedContainer {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-left: 10px;
}

@media (min-width: 768px) {
  .relatedContainer {
    gap: 18px;
  }
}

.iaOptionSearch {
  display: flex;
  gap: 10px;
  user-select: none;
  font-size: 12px;
}

@media (min-width: 768px) {
  .iaOptionSearch {
    margin-left: 10px;
    font-size: 14px;
  }
}

.infoIAView {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.iaOptionSearch button {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  background-color: #e6e6e6;
  padding: 6px 10px;
  transition: 0.3s;
  line-height: 18px;
}

.iaOptionSearch button:hover {
  background-color: #d1d1d1;
}

.lineIA {
  width: 100%;
  height: 1px;
  background-color: #1b2831;
  opacity: 0.16;
  margin: 25px 0 25px;
}

.loadingIAContainer {
  text-align: center;
  height: 100px;
}

.loadingIAContainer img {
  height: 100%;
}

.relatedIACard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 120px;
  padding: 5px;
  border-radius: 10px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 1.4px 0px rgba(27, 40, 49, 0.26);
  transition: 0.3s;
}

@media (min-width: 768px) {
  .relatedIACard {
    padding: 10px;
  }
}

.relatedIACard:hover {
  background: #f6f6f6be;
}

.relatedIACard img {
  width: 20px;
}

@media (min-width: 768px) {
  .relatedIACard img {
    width: 30px;
  }
}
.relatedIACard p {
  /* height: 40px; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #2749b9;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: 16px;
}
